<template>
  <div class="step">
    <div class="box2">
      <img
        v-if="$route.query.type == 2"
        style="width: 190px; height: 290px; margin-right: 38px"
        src="@/assets/images/Continuation.png"
        alt=""
      />
      <div v-else class="imgs">
        <img src="@/assets/images/creatImg.png" alt="" />
      </div>
      <div class="right">
        <div class="success">
          <img src="@/assets/images/succes.png" class="succImgs" alt="" />
          <div class="succText">
            <p v-if="$route.query.type == 2">恭喜，你已获得作品续写权限</p>
            <p v-else>恭喜，你已获得作品开篇创作权限</p>
          </div>
        </div>
        <div class="kuang">
          <span>第二步：开通AI对话频道，开启你的AI创作之路</span>
        </div>
        <el-button size="small" type="primary" class="button" @click="toAI"
          ><span
            v-if="$route.query.type == 2"
            style="color: #000; font-weight: 600"
            >开始续写</span
          >
          <span v-else style="color: #000; font-weight: 600">开篇创作</span>
          <i
            class="el-icon-right"
            style="color: #000; font-weight: 600; font-size: 16px"
          ></i>
        </el-button>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "step",
  data() {
    return {};
  },
  mounted() {},
  methods: {
    toAI() {
      this.$router.push({
        path: "/AICreate",
        query: {
          type: this.$route.query.type,
        },
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(0.2); /*开始为原始大小*/
  }
  25% {
    transform: scale(0.4); /*放大1.1倍*/
  }
  50% {
    transform: scale(0.6);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
.step {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: url(../../assets/images/loginbg.png) no-repeat;
  background-size: 100% 100%;
  padding-top: 100px;
  .box2 {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .imgs {
      width: 190px;
      height: 290px;
      margin-right: 38px;
      // border-radius: 8px;
      // overflow: hidden;
      // border: 4px solid #999;
      animation-name: scaleDraw; /*关键帧名称*/
      animation-timing-function: ease-in-out; /*动画的速度曲线*/
      animation-duration: 0.5s; /*动画所花费的时间*/
      img {
        width: 100%;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      .success {
        display: flex;
        align-items: center;
        .succImgs {
          width: 60px;
        }
        .succText {
          margin-left: 30px;
          font-size: 16px;
          line-height: 30px;
        }
      }

      .kuang {
        height: 48px;
        line-height: 48px;
        width: 300px;
        margin: 50px 0;
        text-align: center;
        border-radius: 20px;
        padding: 0 20px;
        background: #06070c70;
        color: #fffbfb;
      }
      .button {
        width: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
  